
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { TransactionProp } from '@/types/Investment';
import { getTransactions } from '@/modules/requests';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  setup: () => {
    const { currentRoute }: any = useRouter();
    const transactions = ref<TransactionProp[]>([]);
    const loading = ref<boolean>(false);

    const currentTab = computed(
      () => !!currentRoute.value.name?.match(/Dashboard/),
    );

    const getTransactionsBasedOnRoute = computed(() => {
      return currentRoute.value
        ? // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          transactions.value?.splice(0, 2)
        : transactions.value;
    });

    const statusBadge = (status: string): string => {
      switch (status) {
        case 'success':
          return "<div class='inline-flex items-center'><div class='w-1.5 h-1.5 bg-invest-green rounded-full mr-2'></div> Processed</div>";
        case 'pending':
        default:
          return "<div class='inline-flex items-center'><div class='w-1.5 h-1.5 bg-invest-orange rounded-full mr-2'></div> Pending</div>";
      }
    };

    const getInvestmentTransactions = async (page = 1): Promise<any> => {
      loading.value = true;
      await getTransactions(page)
        .then((response: AxiosResponse) => {
          transactions.value = response?.data?.data as TransactionProp[];
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (loading.value = false));
    };

    onBeforeMount(() => getInvestmentTransactions());

    return {
      transactions,
      currentTab,
      getTransactionsBasedOnRoute,
      statusBadge,
      loading,
    };
  },
});
