<template>
  <div
    class="w-full sm:w-5/12 mb-5 animated reveal"
    :class="currentTab ? 'mt-12' : 'mt-0'"
  >
    <div class="flex justify-between items-center">
      <h3 class="text-sm font-medium">
        {{ currentTab ? 'Recent Transactions' : 'All transactions' }}
      </h3>
      <router-link
        v-if="currentTab"
        :to="{ name: 'Transactions' }"
        class="
          bg-invest-mediumblue bg-opacity-8
          w-18
          rounded-sm
          flex
          justify-center
          items-center
          h-8
          text-invest-purple text-xs
        "
      >
        View all
      </router-link>
    </div>
    <template v-if="loading">
      <skeleton-loader
        width="10px"
        height="80px"
        class-name="rounded w-full mt-5"
        v-for="i in 5"
        :key="i"
      />
    </template>

    <template v-else>
      <template v-if="getTransactionsBasedOnRoute.length">
        <div
          class="flex flex-col"
          :class="index + 1 === 1 ? 'mt-5' : 'mt-8'"
          v-for="(transaction, index) in getTransactionsBasedOnRoute"
          :key="index"
        >
          <div class="flex items-center justify-between">
            <div class="inline-flex">
              <div
                class="
                  flex
                  items-center
                  justify-center
                  rounded-full
                  bg-invest-mediumblue bg-opacity-8
                  w-10
                  h-10
                "
              ></div>
              <div class="flex flex-col ml-5">
                <h5 class="text-sm">{{ transaction.investment.name }}</h5>
                <h5 class="text-xs text-invest-darkgray mt-1">Oct 22, 2020</h5>
              </div>
            </div>
            <div class="flex flex-col">
              <h5 class="text-sm">
                {{
                  transaction.type === 'investment'
                    ? `+ ${$filters.formatAmount(transaction.amount)}`
                    : `- ${$filters.formatAmount(transaction.amount)}`
                }}
              </h5>
              <p
                class="text-xs text-invest-darkgray mt-1"
                v-html="statusBadge(transaction.status)"
              ></p>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-col">
          <p class="mt-3 text-10 text-invest-dark">
            You currently have no transactions.
          </p>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { TransactionProp } from '@/types/Investment';
import { getTransactions } from '@/modules/requests';
import { AxiosResponse, AxiosError } from 'axios';

export default defineComponent({
  setup: () => {
    const { currentRoute }: any = useRouter();
    const transactions = ref<TransactionProp[]>([]);
    const loading = ref<boolean>(false);

    const currentTab = computed(
      () => !!currentRoute.value.name?.match(/Dashboard/),
    );

    const getTransactionsBasedOnRoute = computed(() => {
      return currentRoute.value
        ? // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          transactions.value?.splice(0, 2)
        : transactions.value;
    });

    const statusBadge = (status: string): string => {
      switch (status) {
        case 'success':
          return "<div class='inline-flex items-center'><div class='w-1.5 h-1.5 bg-invest-green rounded-full mr-2'></div> Processed</div>";
        case 'pending':
        default:
          return "<div class='inline-flex items-center'><div class='w-1.5 h-1.5 bg-invest-orange rounded-full mr-2'></div> Pending</div>";
      }
    };

    const getInvestmentTransactions = async (page = 1): Promise<any> => {
      loading.value = true;
      await getTransactions(page)
        .then((response: AxiosResponse) => {
          transactions.value = response?.data?.data as TransactionProp[];
        })
        .catch((error: AxiosError) => console.log(error))
        .finally(() => (loading.value = false));
    };

    onBeforeMount(() => getInvestmentTransactions());

    return {
      transactions,
      currentTab,
      getTransactionsBasedOnRoute,
      statusBadge,
      loading,
    };
  },
});
</script>
